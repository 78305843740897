import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AllStateContext from "../../context/allstateContext";
import { getAccountList, getCategoryList } from "../Apis/apis";
import { IS_DIALOG_ACCOUNT_CATEGORY_TYPE } from "../../Constant/constant";
import AddNewCategoryDialog from "../../Common/AddNewCategoryDialog";
import AddNewAccountDialog from "../../Common/AddNewAccountDialog";
const AccountDialog = () => {
  const {
    openAccount,
    setOpenAccount,
    selectedAccount,
    setSelectedAccount,
    selectedCategory,
    setSelectedCategory,
    transactionType,
    setTransactionType,
    isAccountOrCategoryDialog,
    setIsAccountOrCategoryDialog,
    setOpenAddNewCategoryDialog,
    setAddNewAccountDialog,
    addNewAccountDialog,
    openAddNewCategoryDialog,
  } = useContext(AllStateContext);

  const [data, setData] = useState([]);

  // Fetch data from API when the dialog opens

  const getData = async () => {
    try {
      if (
        isAccountOrCategoryDialog === IS_DIALOG_ACCOUNT_CATEGORY_TYPE.ACCOUNT
      ) {
        const response = await getAccountList();
        setData(response.data);
      } else {
        const filter = { type: transactionType };
        const response = await getCategoryList(filter);
        setData(response.data);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getData();
  }, []);

  const onclickItem = (item) => {
    if (isAccountOrCategoryDialog === IS_DIALOG_ACCOUNT_CATEGORY_TYPE.ACCOUNT) {
      setSelectedAccount({
        name: item.name,
        accountId: item.accountId,
        icon: item?.iconDetail?.icon,
      });
      setOpenAccount(false);
    } else {
      setSelectedCategory({
        name: item.name,
        categoryId: item.categoryId,
        icon: item?.iconDetail?.icon,
      });
      setOpenAccount(false);
    }
  };
  const newAddClick = () => {
    if (isAccountOrCategoryDialog === IS_DIALOG_ACCOUNT_CATEGORY_TYPE.ACCOUNT) {
      setAddNewAccountDialog(true);
    } else {
      setOpenAddNewCategoryDialog(true);
    }
  };

  return (
    <>
      {addNewAccountDialog && <AddNewAccountDialog refetch={getData} />}
      {openAddNewCategoryDialog && <AddNewCategoryDialog refetch={getData} />}
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={openAccount}
          // onClose={() => setOpenAccount(false)}
        >
          <DialogTitle>Select Account</DialogTitle>
          <DialogContent>
            <div className="grid-container">
              {data?.payload?.data?.map((item) => (
                <div key={item.id} className="grid-item">
                  <div
                    onClick={() => onclickItem(item)}
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <img
                          style={{ height: "25px", width: "25px" }}
                          src={item?.iconDetail?.icon}
                          alt={item?.iconDetail?.name}
                        />
                      </div>
                      <div>{item?.name}</div>
                    </div>
                    <div>
                      <div>{item?.balance}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginTop: "10px",
                  height: "28px",
                }}
                onClick={() => {
                  newAddClick();
                }}
              >
                <img
                  style={{ width: "18px", height: "18px" }}
                  src={require("../../Common/Icon/plus.png")}
                  alt="Small Image"
                  className="img-fluid mr-2"
                />
                Add new one
              </button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAccount(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AccountDialog;
