import moment from "moment";

export const currentTime = () => {
  const options = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const currentTime = new Date().toLocaleTimeString("en-IN", options);
  return currentTime;
};

export const currentDate = () => {
  const options = { month: "short", day: "numeric", year: "numeric" };
  const currentDate = new Date().toLocaleDateString("en-IN", options);
  return currentDate;
};

export const formatDate = (date) => {
  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = new Date(date).toLocaleDateString("en-IN", options);
  return formattedDate;
};
// date formate Aug, 2023
export const formatDateMonthYear = (oldDate) => {
  const date = moment(oldDate);
  const formattedDate = date.format("MMM, YYYY");

  return formattedDate;
};
export const formateTime = (time) => {
  const options = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const currentTime = new Date(time).toLocaleTimeString("en-IN", options);
  return currentTime;
};
export const formateTimeFromGivenDate = (date) => {
  const options = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const currentTime = new Date(date).toLocaleTimeString("en-IN", options);
  return currentTime;
};

export const currentStartEndDate = () => {
  let currentDate = new Date();

  currentDate.setMonth(currentDate.getMonth());
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const startDate = new Date(currentYear, currentMonth, 1);
  const endDate = new Date(currentYear, currentMonth + 1, 0);
  endDate.setHours(23, 59, 59, 999);
  startDate.setHours(0, 0, 0, 0);
  return { startDate, endDate };
};

export const convertToDateTimeFormat = (date, time) => {
  const dateParts = date.split(" ");
  const day = dateParts[0];
  const month = getMonthNumber(dateParts[1].substr(0, 3));
  const year = dateParts[2];

  const timeParts = time.split(/(\d+):(\d+)(\w+)/);
  let hours = parseInt(timeParts[1]);
  const minutes = parseInt(timeParts[2]);
  const meridiem = timeParts[3];

  if (meridiem === "pm" && hours < 12) {
    hours += 12;
  } else if (meridiem === "am" && hours === 12) {
    hours = 0;
  }

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:00`;

  return `${formattedDate} ${formattedTime}`;
};

const getMonthNumber = (month) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthIndex = months.indexOf(month) + 1;
  return monthIndex.toString().padStart(2, "0");
};
