import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  API_RESP_STATUS,
  IS_DIALOG_ACCOUNT_CATEGORY_TYPE,
  TRANSACTION_TYPE,
} from "../../Constant/constant";
import AllStateContext from "../../context/allstateContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  convertToDateTimeFormat,
  currentDate,
  currentTime,
  formatDate,
  formateTimeFromGivenDate,
} from "../../Common/Common";
import { createTransaction, updateTransaction } from "../Apis/apis";

const AddDailog = ({ fetchData }) => {
  const [result, setResult] = useState(0);
  const [input, setInput] = useState("");
  const [amountNotes, setAmountNotes] = useState({ amount: "", notes: "" });
  const {
    transactionType,
    setTransactionType,
    selectedAccount,
    setSelectedAccount,
    selectedCategory,
    setSelectedCategory,
    setOpenDateDialog,
    setIsAccountOrCategoryDialog,
    open,
    setOpen,
    setOpenAccount,
    selectedDate,
    setSelectedDate,
    setOpenTimeDialog,
    selectedTime,
    setSelectedTime,
    recordItem,
  } = useContext(AllStateContext);
  const handleSave = async () => {
    if (!transactionType) {
      alert("Select Transaction type first ...!");
    } else if (!selectedAccount?.accountId) {
      alert("Select Account first ...!");
    } else if (!selectedCategory?.categoryId) {
      alert("Select Category first ...!");
    } else if (!amountNotes?.amount && amountNotes?.amount != null) {
      alert("Amount must be required...!");
    } else {
      try {
        let response;
        if (!recordItem) {
          const body = {
            accountId: selectedAccount.accountId,
            amount: amountNotes.amount,
            type: transactionType,
            categoryId: selectedCategory?.categoryId,
            notes: amountNotes?.notes,
            date: convertToDateTimeFormat(selectedDate, selectedTime),
          };

          response = await createTransaction(body);
        } else {
          const body = {
            accountId: selectedAccount.accountId,
            amount: amountNotes.amount,
            type: transactionType,
            categoryId: selectedCategory?.categoryId,
            notes: amountNotes?.notes,
            date: convertToDateTimeFormat(selectedDate, selectedTime),
          };

          response = await updateTransaction(
            { transactionId: recordItem.transactionId },
            body
          );
        }
        if (response.data.status == API_RESP_STATUS.success) {
          setAmountNotes({ amount: "", notes: "" });
          fetchData();
          setOpen(false);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const handelAmountNotes = (e) => {
    const { name, value } = e.target;
    setAmountNotes((data) => {
      return { ...data, [name]: value };
    });
  };
  useEffect(() => {
    setSelectedDate(currentDate());
    setSelectedTime(currentTime());
  }, []);

  useMemo(() => {
    if (recordItem) {
      setTransactionType(recordItem.type);

      setSelectedAccount({
        accountId: recordItem.accountId,
        icon: recordItem.accountDetails.iconDetail.icon,
        name: recordItem.accountDetails.name,
      });
      setAmountNotes({
        amount: recordItem.amount,
        notes: recordItem.notes,
      });
      setSelectedCategory({
        categoryId: recordItem.categoryId,
        icon: recordItem.categoryDetails.iconDetail.icon,
        name: recordItem.categoryDetails.name,
      });
      // formateTimeFromGivenDate
      setSelectedTime(formateTimeFromGivenDate(recordItem.date));
      setSelectedDate(formatDate(recordItem.date));
    } else {
      setTransactionType(TRANSACTION_TYPE.EXPENSE);
      setSelectedAccount({
        icon: require("../../Common/Icon/icon_select.png"),
        name: "Account",
      });
      setAmountNotes({
        amount: "",
        notes: "",
      });
      setSelectedCategory({
        icon: require("../../Common/Icon/icon_select.png"),
        name: "Category",
      });
    }
  }, [recordItem]);
  useMemo(() => {
    if (!recordItem) {
      if (transactionType === TRANSACTION_TYPE.TRANSFER) {
        setSelectedAccount("");
        setSelectedCategory("");
      } else {
        setSelectedCategory("");
      }
    }
  }, [transactionType]);

  const dialogSelectAccount = (type) => {
    setIsAccountOrCategoryDialog(type);
    setOpenAccount(true);
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">
          {"create Transaction"}
        </DialogTitle>
        <DialogContent>
          {/* <AccountDialog /> */}
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <div
              style={{ display: "flex", gap: "5px", cursor: "pointer" }}
              onClick={() => setTransactionType(TRANSACTION_TYPE.INCOME)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    display:
                      transactionType === TRANSACTION_TYPE.INCOME
                        ? "block"
                        : "none",
                  }}
                  src={require("../../Common/Icon/icon_select.png")}
                  alt=""
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                INCOME
              </div>
            </div>
            <span>|</span>
            <div
              style={{ display: "flex", gap: "5px", cursor: "pointer" }}
              onClick={() => setTransactionType(TRANSACTION_TYPE.EXPENSE)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    display:
                      transactionType === TRANSACTION_TYPE.EXPENSE
                        ? "block"
                        : "none",
                  }}
                  src={require("../../Common/Icon/icon_select.png")}
                  alt=""
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                EXPENSE
              </div>
            </div>
            <span>|</span>
            <div
              style={{ display: "flex", gap: "5px", cursor: "pointer" }}
              onClick={() => setTransactionType(TRANSACTION_TYPE.TRANSFER)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    display:
                      transactionType === TRANSACTION_TYPE.TRANSFER
                        ? "block"
                        : "none",
                  }}
                  src={require("../../Common/Icon/icon_select.png")}
                  alt=""
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                TRANSFER
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
            }}
          >
            <div>
              {transactionType === TRANSACTION_TYPE.TRANSFER
                ? "From"
                : "Amount"}
            </div>
            <div> </div>
            <div>
              {transactionType == TRANSACTION_TYPE.TRANSFER ? "To" : "Category"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <button
              onClick={() =>
                dialogSelectAccount(IS_DIALOG_ACCOUNT_CATEGORY_TYPE.ACCOUNT)
              }
              type="button"
              className="btn btn-outline-primary"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={
                  selectedAccount
                    ? selectedAccount.icon
                    : require("../../Common/Icon/icon_select.png")
                }
                alt="icon-select"
                className="img-fluid mr-2"
              />
              {selectedAccount ? selectedAccount.name : "Account"}
            </button>

            <div></div>
            <button
              onClick={() =>
                dialogSelectAccount(IS_DIALOG_ACCOUNT_CATEGORY_TYPE.CATEGORY)
              }
              type="button"
              className="btn btn-outline-primary"
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <img
                style={{ width: "20px", height: "20px" }}
                src={
                  selectedCategory
                    ? selectedCategory.icon
                    : require("../../Common/Icon/icon_select.png")
                }
                alt="Small Image"
                className="img-fluid mr-2"
              />
              {transactionType === TRANSACTION_TYPE.TRANSFER
                ? "Account"
                : `${selectedCategory ? selectedCategory.name : "Category"}`}
            </button>
          </div>
          <div className="form-floating" style={{ marginBottom: "10px" }}>
            <input
              name="amount"
              type="number"
              onChange={handelAmountNotes}
              value={amountNotes.amount}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              required
            ></input>
            <label htmlFor="floatingTextarea">Amount</label>
          </div>
          <div className="form-floating" style={{ marginBottom: "10px" }}>
            <textarea
              name="notes"
              onChange={handelAmountNotes}
              value={amountNotes.notes}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
            ></textarea>
            <label htmlFor="floatingTextarea">Notes</label>
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <button
              onClick={() => setOpenDateDialog(true)}
              type="button"
              className="btn btn-outline-primary"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                height: "23px",
              }}
            >
              {selectedDate}
            </button>

            <div></div>
            <button
              onClick={() => setOpenTimeDialog(true)}
              type="button"
              className="btn btn-outline-primary"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                height: "23px",
              }}
            >
              {selectedTime}
            </button>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
          >
            Save changes
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddDailog;
