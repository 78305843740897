import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_RESP_STATUS } from "../../Constant/constant";
import { getAccountList, getCategoryList } from "../Apis/apis";
import AddDailog from "../Record/AddDailog";
import AllStateContext from "../../context/allstateContext";
import AccountDialog from "../Record/AccountDialog";
import DateDialog from "../../Common/DateDialog";
import TimeDialog from "../../Common/TimeDialog";
import { useQuery } from "react-query";
import AddNewAccountDialog from "../../Common/AddNewAccountDialog";
import DeletedConfirmationDialog from "../../Common/DeletedConfirmationDialog";
import AddNewCategoryDialog from "../../Common/AddNewCategoryDialog";

const Categories = () => {
  const history = useNavigate();
  const [groupData, setGroupData] = useState([]);
  const {
    openAccount,
    openDateDialog,
    openTimeDialog,
    openDeleteConfirmationDialog,
    setOpenDeleteConfirmationDialog,
    addNewAccountDialog,
    openAddNewCategoryDialog,
    setOpenAddNewCategoryDialog,
    selectedCategoriesItem,
    setSelectedCategoriesItem,
  } = useContext(AllStateContext);
  const { data, error, isLoading, refetch } = useQuery(
    ["CategoriesListApiCall"],
    async () => {
      const response = await getCategoryList();
      setGroupData(
        response.data?.payload?.data?.reduce((acc, item) => {
          const type = item.type;
          acc[type] = acc[type] || [];
          acc[type].push(item);
          return acc;
        }, {})
      );
      return response.data;
    }
  );
  if (error) {
    if (error.response.status == API_RESP_STATUS.unauthorized) {
      localStorage.removeItem("token");
      history("/login");
    }
  }

  const handelEdit = async (item, event) => {
    if (event === "edit") {
      setSelectedCategoriesItem(item);
      setOpenAddNewCategoryDialog(true);
    } else {
      setOpenDeleteConfirmationDialog(true);
    }
  };
  const handelDeleted = async () => {
    alert("Important: Functionality Under Development - Proceed with Caution");
  };
  return (
    <div className="container">
      <div className="container">
        {isLoading ? (
          <div className="container">
            <h1>Data Loading...!</h1>
          </div>
        ) : (
          <>
            <AddDailog fetchData={refetch} />
            {openAccount && <AccountDialog />}
            {openDateDialog && <DateDialog />}
            {openTimeDialog && <TimeDialog />}
            {openDeleteConfirmationDialog && (
              <DeletedConfirmationDialog isDeleted={handelDeleted} />
            )}
            {addNewAccountDialog && <AddNewAccountDialog refetch={refetch} />}
            {openAddNewCategoryDialog && (
              <AddNewCategoryDialog refetch={refetch} />
            )}
            <div
              id="chart"
              style={{ display: "flex", justifyContent: "center" }}
            ></div>
            <div>
              <ul className="list-group" style={{ marginTop: "20px" }}>
                {groupData &&
                  Object.entries(groupData)?.map(([type, items]) => (
                    <Fragment key={type}>
                      <li className="list-group-item">
                        <div style={{ fontWeight: "bold" }}>
                          {type == 2
                            ? "Income Categories"
                            : "Expense Categories"}
                        </div>
                      </li>
                      {items?.map((item) => (
                        <li className="list-group-item" key={item?.categoryId}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex", gap: "12px" }}>
                              <div>
                                <img
                                  style={{ height: "50px", width: "50px" }}
                                  src={item?.iconDetail?.icon}
                                  alt={item?.name}
                                />
                              </div>
                              <div
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <div style={{ fontWeight: "500" }}>
                                  {item.name}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                gap: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                onClick={() => handelEdit(item, "edit")}
                                src={require("../../Common/Icon/edit.png")}
                                alt="more_image"
                              />
                              <img
                                onClick={() => handelEdit(item, "delete")}
                                src={require("../../Common/Icon/delete_icon.png")}
                                alt="more_image"
                              />
                            </div>
                          </div>
                        </li>
                      ))}
                    </Fragment>
                  ))}
              </ul>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  style={{
                    marginTop: "10px",
                    marginBottom: "15px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  onClick={() => setOpenAddNewCategoryDialog(true)}
                >
                  <img
                    style={{ width: "20px" }}
                    id="basic-button"
                    aria-haspopup="true"
                    src={require("../../Common/Icon/plus.png")}
                    alt="more_image"
                  />
                  ADD NEW CATEGORY
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Categories;
