import React, { Fragment, useContext, useState } from "react";
import DateView from "../Record/DateView";
import { useNavigate } from "react-router-dom";
import {
  API_RESP_STATUS,
  PERCENTAGE_SYMBOL,
  RUPEE_SYMBOL,
} from "../../Constant/constant";
import { getBudgetAnalysis } from "../Apis/apis";
import ProgressBar from "@ramonak/react-progress-bar";
import AddDailog from "../Record/AddDailog";
import AllStateContext from "../../context/allstateContext";
import AccountDialog from "../Record/AccountDialog";
import DateDialog from "../../Common/DateDialog";
import TimeDialog from "../../Common/TimeDialog";
import { useQuery } from "react-query";
import { formatDateMonthYear } from "../../Common/Common";
import AddNewAccountDialog from "../../Common/AddNewBudgetDialog";

const Budgets = () => {
  const history = useNavigate();
  const {
    openAccount,
    openDateDialog,
    openTimeDialog,
    startEndDate,
    addNewBudgetDialog,
    setAddNewBudgetDialog,
    selectedBudgetItem,
    setSelectedBudgetItem,
  } = useContext(AllStateContext);
  const [selectedOption, setSelectedOption] = useState("expense");

  const handleSelectionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const { data, error, isLoading, refetch } = useQuery(
    ["budgetListApiCall", startEndDate, selectedOption],
    async () => {
      const response = await getBudgetAnalysis(
        { type: selectedOption === "expense" ? 1 : 2 },
        formatDateMonthYear(startEndDate.startDate),
        "", // startEndDate.endDate,
        true
      );
      return response.data;
    }
  );

  const handleClick = (item, event) => {
    if (event === "edit") {
      setSelectedBudgetItem(item);
      setAddNewBudgetDialog(true);
    }
  };

  if (error) {
    if (error.response.status == API_RESP_STATUS.unauthorized) {
      localStorage.removeItem("token");
      history("/login");
    }
  }

  const handleSetBudget = (item) => {
    setSelectedBudgetItem(item);
    setAddNewBudgetDialog(true);
  };

  return (
    <div className="container">
      <div className="container">
        {isLoading ? (
          <div className="container">
            <h1>Data Loading...!</h1>
          </div>
        ) : (
          <>
            <DateView
              totalExpense={data?.payload?.totalInEx?.totalExpense}
              totalIncome={data?.payload?.totalInEx?.totalIncome}
            />
            <AddDailog fetchData={refetch} />
            {openAccount && <AccountDialog />}
            {openDateDialog && <DateDialog />}
            {openTimeDialog && <TimeDialog />}
            {addNewBudgetDialog && <AddNewAccountDialog refetch={refetch} />}
            <div className="container">
              <select
                value={selectedOption}
                onChange={handleSelectionChange}
                className={`form-select ${
                  selectedOption === "income"
                    ? "bg-success text-white"
                    : selectedOption === "expense"
                    ? "bg-danger text-white"
                    : ""
                }`}
              >
                <option value="income">Income</option>
                <option value="expense">Expense</option>
              </select>
            </div>
            <div
              id="chart"
              style={{ display: "flex", justifyContent: "center" }}
            ></div>
            <div>
              <ul className="list-group" style={{ marginTop: "20px" }}>
                {data && (
                  <Fragment>
                    {data?.payload?.data
                      ?.filter((item) => item.budgetDetail) // Filter out items with null budgetDetail
                      .map((item, i) => (
                        <li className="list-group-item" key={item?.categoryId}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex", gap: "12px" }}>
                              <div>
                                <img
                                  style={{ height: "50px", width: "50px" }}
                                  src={item?.iconDetail?.icon}
                                  alt={item?.name}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div style={{ fontWeight: "bold" }}>
                                      {item.name}
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        gap: "5px",
                                      }}
                                    >
                                      <img
                                        id="basic-button"
                                        style={{
                                          width: "25px",
                                          marginRight: "10px",
                                        }}
                                        aria-haspopup="true"
                                        onClick={() =>
                                          handleClick(item, "edit")
                                        }
                                        src={require("../../Common/Icon/edit.png")}
                                        alt="more_image"
                                      />
                                      <img
                                        id="basic-button"
                                        style={{ width: "25px" }}
                                        aria-haspopup="true"
                                        onClick={() =>
                                          handleClick(item, "delete")
                                        }
                                        src={require("../../Common/Icon/delete_icon.png")}
                                        alt="more_image"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    {"Limit: " +
                                      RUPEE_SYMBOL +
                                      item.budgetDetail.limit}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      {"Spent: " +
                                        RUPEE_SYMBOL +
                                        (item.spent !== null
                                          ? item.spent
                                          : "0")}
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {item.budgetDetail.month}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      {"Remaining: " +
                                        RUPEE_SYMBOL +
                                        (item.budgetDetail.limit - item.spent >
                                        0
                                          ? item.budgetDetail.limit - item.spent
                                          : "0")}
                                    </div>
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                        cursor: "pointer",
                                        color: "green",
                                      }}
                                    >
                                      {PERCENTAGE_SYMBOL +
                                        ((item.spent * 100) /
                                          item.budgetDetail.limit <
                                        100
                                          ? (
                                              (item.spent * 100) /
                                              item.budgetDetail.limit
                                            ).toFixed(2)
                                          : "100.00")}
                                    </div>
                                  </div>

                                  <div>
                                    <ProgressBar
                                      completed={
                                        (item.spent * 100) /
                                        item.budgetDetail.limit
                                      }
                                      maxCompleted={100}
                                      width="62vw"
                                      bgColor="#008ffb"
                                      customLabel={" "}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}

                    {data?.payload?.data
                      ?.filter((item) => !item.budgetDetail) // Filter out items without budgetDetail
                      .map((item) => (
                        <li className="list-group-item" key={item?.categoryId}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex", gap: "12px" }}>
                              <div>
                                <img
                                  style={{ height: "50px", width: "50px" }}
                                  src={item?.iconDetail?.icon}
                                  alt={item?.name}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                <div>{item.name}</div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                                onClick={() => handleSetBudget(item)}
                              >
                                SET BUDGET
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                  </Fragment>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Budgets;
