export const API_URL = "https://money-api.multicodesolution.com";
// export const API_URL = "http://localhost:3033";
export const MIDDLE_URL_PUBLIC = "/my-money/public/api/v1";
export const MIDDLE_URL = "/my-money/api/v1";

// api response status
export const API_RESP_STATUS = {
  success: 200,
  unauthorized: 401,
  forbidden: 403,
  nonFound: 404,
};

export const TRANSACTION_TYPE = {
  INCOME: 2,
  EXPENSE: 1,
  TRANSFER: 3,
};
export const FILTER_TYPE = {
  DAILY: 1,
  WEEK: 7,
  MONTHLY: 2,
  MONTH_3: 3,
  MONTH_6: 6,
  YEARLY: 12,
  ALL: 0,
};
export const RUPEE_SYMBOL = "₹";
export const PERCENTAGE_SYMBOL = "%";

export const IS_DIALOG_ACCOUNT_CATEGORY_TYPE = {
  ACCOUNT: 1,
  CATEGORY: 2,
};
