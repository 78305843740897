import "./App.css";
import { Login } from "./components/Login/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navbar } from "./components/NavBar/Navbar";
import { Record } from "./components/Record/Record";
import AllStates from "./context/allStates";
import MainPage from "./Routes/MainPage";
import ProtectedRouts from "./Routes/ProtectedRouts";
import Analisys from "./components/Analisys/Analisys";
import Budgets from "./components/Budget/Budgets";
import Accounts from "./components/Accounts/Accounts";
import Categories from "./components/Categories/Categories";
import ContactTransaction from "./components/ContactTransaction/ContactTransaction";

function App() {
  return (
    <>
      <Router>
        <AllStates>
          <div style={{ marginTop: "55px" }}>
            <Routes>
              <Route element={<MainPage />} path="/">
                <Route element={<ProtectedRouts />}>
                  <Route element={<Record />} path="/" />
                  <Route element={<Record />} path="/home" />
                  <Route element={<Analisys />} path="/analysis" />
                  <Route element={<Budgets />} path="/budgets" />
                  <Route element={<Accounts />} path="/account" />
                  <Route element={<Categories />} path="/categories" />
                  <Route element={<ContactTransaction />} path="/contact" />
                </Route>
              </Route>
              <Route element={<Login />} path="/login" />
              <Route
                element={<h1 style={{ marginTop: "50px" }}>Page not found</h1>}
                path="/*"
              />
            </Routes>
          </div>
        </AllStates>
      </Router>
    </>
  );
}

export default App;
