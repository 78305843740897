import React, { useContext, useState } from "react";
import AllStateContext from "../context/allstateContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatDate } from "./Common";

const DateDialog = () => {
  const { openDateDialog, setOpenDateDialog, selectedDate, setSelectedDate } =
    useContext(AllStateContext);

  const handleDateChange = (date) => {
    setSelectedDate(formatDate(date));
    setOpenDateDialog(false);
  };
  const handleOpen = () => {
    // Handle the open event of the dialog
  };

  const handleClose = () => {
    setOpenDateDialog(false);
  };

  const handleSave = () => {
    setOpenDateDialog(false);
  };

  return (
    <div>
      {/* <button onClick={handleOpen}>Open Dialog</button> */}

      {/* Dialog component */}
      <Dialog open={openDateDialog} onClose={handleClose}>
        <DialogTitle>Select Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                onChange={handleDateChange}
                label="Basic date picker"
              />
            </DemoContainer>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <button onClick={handleSave}>Save</button>
          <button onClick={handleClose}>Close</button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DateDialog;
