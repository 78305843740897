import React, { useContext, useEffect, useMemo, useState } from "react";
import AllStateContext from "../context/allstateContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { API_RESP_STATUS, TRANSACTION_TYPE } from "../Constant/constant";
import {
  createAccount,
  createCategory,
  getIconList,
  updateAccount,
  updateBudget,
  updateCategory,
} from "../components/Apis/apis";
import { useQuery } from "react-query";

const AddNewCategoryDialog = ({ refetch }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [balanceName, setBalanceName] = useState({ balance: "", name: "" });
  const {
    openAddNewCategoryDialog,
    setOpenAddNewCategoryDialog,
    transactionType,
    setTransactionType,
    selectedCategoriesItem,
    setSelectedCategoriesItem,
  } = useContext(AllStateContext);

  useMemo(() => {
    if (selectedCategoriesItem) {
      setBalanceName({
        name: selectedCategoriesItem.name,
      });
      setTransactionType(selectedCategoriesItem.type);
      setSelectedItem(selectedCategoriesItem.iconId);
    } else {
      setTransactionType(TRANSACTION_TYPE.EXPENSE);
    }
  }, [openAddNewCategoryDialog]);

  const {
    data: iconData,
    error: iconError,
    isLoading,
    refetch: iconRefetch,
  } = useQuery(["iconListApiCall"], async () => {
    const showAll = true;
    const response = await getIconList(showAll, {
      // type:
      //   transactionType == TRANSACTION_TYPE.INCOME
      //     ? TRANSACTION_TYPE.EXPENSE
      //     : 3,
    });
    return response.data.payload.data;
  });

  const handleClose = () => {
    setOpenAddNewCategoryDialog(false);
    setSelectedCategoriesItem("");
    setBalanceName({ balance: "", name: "" });
  };
  const handelAmountNotes = (e) => {
    const { name, value } = e.target;
    setBalanceName((data) => {
      return { ...data, [name]: value };
    });
  };
  const handleSave = async () => {
    if (!balanceName.name) {
      alert("Name is required ...!");
    } else if (!selectedItem && !selectedItem == 0) {
      alert("Please select icon...!");
    } else {
      try {
        let response;
        if (!selectedCategoriesItem) {
          const body = {
            name: balanceName.name,
            type: transactionType,
            iconId: selectedItem,
          };
          response = await createCategory(body);
        } else {
          const body = {
            name: balanceName.name,
            type: transactionType,
            iconId: selectedItem,
          };
          response = await updateCategory(
            { categoryId: selectedCategoriesItem.categoryId },
            body
          );
        }
        if (
          response?.data &&
          response?.data?.status == API_RESP_STATUS.success
        ) {
          refetch();
          setOpenAddNewCategoryDialog(false);
          alert(response.data.message);
          setSelectedCategoriesItem("");
          setBalanceName({ balance: "", name: "" });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        alert(error.response.data.message);
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        open={openAddNewCategoryDialog}
      >
        <DialogTitle>Categories</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Type:</div>
            <div
              style={{ display: "flex", gap: "5px", cursor: "pointer" }}
              onClick={() => setTransactionType(TRANSACTION_TYPE.INCOME)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    display:
                      transactionType === TRANSACTION_TYPE.INCOME
                        ? "block"
                        : "none",
                  }}
                  src={require("../Common/Icon/icon_select.png")}
                  alt=""
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                INCOME
              </div>
            </div>
            <span>|</span>
            <div
              style={{ display: "flex", gap: "5px", cursor: "pointer" }}
              onClick={() => setTransactionType(TRANSACTION_TYPE.EXPENSE)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    display:
                      transactionType === TRANSACTION_TYPE.EXPENSE
                        ? "block"
                        : "none",
                  }}
                  src={require("../Common/Icon/icon_select.png")}
                  alt=""
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                EXPENSE
              </div>
            </div>
          </div>
          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <input
              name="name"
              type="string"
              onChange={handelAmountNotes}
              value={balanceName.name}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              required
            ></input>
            <label htmlFor="floatingTextarea">Name</label>
          </div>
          Icon
          <div
            className="form-floating"
            style={{
              marginBottom: "10px",
              border: "1px solid gray",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <div className="horizontal-list-view">
              {iconData?.map((iconItem) => (
                <div
                  className={`list-item ${
                    selectedItem === iconItem.iconId ? "selected" : ""
                  }`}
                  key={iconItem.iconId}
                  onClick={() => setSelectedItem(iconItem.iconId)}
                >
                  <img
                    style={{ width: "35px", height: "35px" }}
                    src={iconItem.icon}
                    alt="Small Image"
                    className="img-fluid mr-2"
                  />
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-outline-primary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={handleSave}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewCategoryDialog;
