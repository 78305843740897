import React, { useContext } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AllStateContext from "../context/allstateContext";
import { formateTime } from "./Common";
const TimeDialog = () => {
  const { openTimeDialog, setOpenTimeDialog } = useContext(AllStateContext);
  const { selectedTime, setSelectedTime } = useContext(AllStateContext);

  const handleTimeChange = (time) => {
    setSelectedTime(formateTime(time));
  };

  const handleClose = () => {
    setOpenTimeDialog(false);
  };

  const handleSave = () => {
    setOpenTimeDialog(false);
  };
  return (
    <div>
      {/* <button onClick={handleOpen}>Open Dialog</button> */}

      {/* Dialog component */}
      <Dialog open={openTimeDialog} onClose={handleClose}>
        <DialogTitle>Select Time</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                onChange={handleTimeChange}
                label="Basic Time picker"
              />
            </DemoContainer>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <button onClick={handleSave}>Save</button>
          <button onClick={handleClose}>Close</button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TimeDialog;
