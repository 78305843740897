import axios from "axios";
import { API_URL, MIDDLE_URL } from "../../Constant/constant";
import moment from "moment";

// Function to handle common API headers and configurations
let apiClient = axios.create({
  baseURL: API_URL + MIDDLE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
export const setAxiosConfig = () => {
  apiClient = axios.create({
    baseURL: API_URL + MIDDLE_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

// API endpoints
export const endpoints = {
  transaction: "/transaction",
  accountMaster: "/accountMaster",
  getCategoryList: "/categoryMaster",
  budgetAnalysis: "/budget",
  iconMaster: "/icon",
  contact: "/contact",
  contactTransaction: "/contact/transaction",
};

// Function to make a get transactionList
export const getTransactionList = (filter, startDate, endDate, showAll) => {
  const params = {
    search: JSON.stringify(filter),
    // startDate:moment(startDate).utc().startOf("day"),
    startDate:moment(startDate).utc().startOf("day").add(1, 'day'),
    endDate:moment(endDate).utc().endOf("day"),
    showAll,
  };
  return apiClient.get(endpoints.transaction, { params });
};
// Function to make a get IconList
export const getIconList = (showAll, filter) => {
  const params = {
    showAll,
    search: JSON.stringify(filter),
  };
  return apiClient.get(endpoints.iconMaster, { params });
};
// Function to make a create transaction
export const createTransaction = (body) => {
  return apiClient.post(endpoints.transaction, body);
};
// Function to make a update transaction
export const updateTransaction = (queryParams, body) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return apiClient.put(`${endpoints.transaction}?${queryString}`, body);
};

//get account list api
export const getAccountList = (filter, showAll = true) => {
  const params = {
    search: JSON.stringify(filter),
    showAll,
  };
  return apiClient.get(endpoints.accountMaster, { params });
};

//update account master api
export const updateAccount = (queryParams, body) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return apiClient.put(`${endpoints.accountMaster}?${queryString}`, body);
};
// create account
export const createAccount = (body) => {
  return apiClient.post(endpoints.accountMaster, body);
};
//get category list api
export const getCategoryList = (filter, showAll = true) => {
  const params = {
    search: JSON.stringify(filter),
    showAll,
  };
  return apiClient.get(endpoints.getCategoryList, { params });
};
//create category api
export const createCategory = (body) => {
  return apiClient.post(endpoints.getCategoryList, body);
};
// update category
export const updateCategory = (queryParams, body) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return apiClient.put(`${endpoints.getCategoryList}?${queryString}`, body);
};

// get budget and analysis list
export const getBudgetAnalysis = (filter, startDate, endDate, showAll) => {
  const params = {
    search: JSON.stringify(filter),
    startDate,
    endDate,
    showAll,
  };
  return apiClient.get(endpoints.budgetAnalysis, { params });
};
// create budget
export const createBudget = (body) => {
  return apiClient.post(endpoints.budgetAnalysis, body);
};
// update budget
export const updateBudget = (queryParams, body) => {
  const queryString = new URLSearchParams(queryParams).toString();
  return apiClient.put(`${endpoints.budgetAnalysis}?${queryString}`, body);
};

//get contact list api
export const getContactList = (filter, showAll = true) => {
  const params = {
    search: JSON.stringify(filter),
    showAll,
  };
  return apiClient.get(endpoints.contact, { params });
};
//get contact transaction list api
export const getContactTransactionList = (filter, showAll = true) => {
  const params = {
    search: JSON.stringify(filter),
    showAll,
  };
  return apiClient.get(endpoints.contactTransaction, { params });
};

// create contact transaction list api
export const createContactTransaction = (body) => {
  return apiClient.post(endpoints.contact, body);
};
