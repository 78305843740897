import axios from "axios";
import { API_URL, MIDDLE_URL_PUBLIC } from "../../../Constant/constant";

// Function to handle common API headers and configurations
const apiClient = axios.create({
  baseURL: API_URL + MIDDLE_URL_PUBLIC,
  // Add any headers or configurations here
});

// API endpoints
export const endpoints = {
  login: "/login",
  getData: "/data",
  // Add more endpoints as needed
};

// Function to make a login API call
export const login = (number, password) => {
  return apiClient.post(endpoints.login, { number, password });
};

// Function to make a data retrieval API call
export const fetchData = () => {
  return apiClient.get(endpoints.getData);
};
