import React, { useContext, useMemo, useState } from "react";
import AllStateContext from "../context/allstateContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { formatDateMonthYear } from "./Common";
import { createBudget, updateBudget } from "../components/Apis/apis";
import { API_RESP_STATUS } from "../Constant/constant";

const AddNewBudgetDialog = ({ refetch }) => {
  const {
    selectedDate,
    setSelectedDate,
    addNewBudgetDialog,
    setAddNewBudgetDialog,
    selectedBudgetItem,
    startEndDate,
    setSelectedBudgetItem,
  } = useContext(AllStateContext);
  const [limit, setLimit] = useState("");

  const handleClose = () => {
    setSelectedBudgetItem("");
    setAddNewBudgetDialog(false);
  };
  useMemo(() => {
    if (selectedBudgetItem?.budgetDetail != null) {
      setLimit(selectedBudgetItem.budgetDetail.limit);
    }
  }, [selectedBudgetItem]);
  const handelAmountNotes = (e) => {
    const { value } = e.target;
    setLimit(value);
  };
  const handleSave = async () => {
    console.log("limit", limit);
    if (!limit) {
      alert("Limit is required ...!");
    } else {
      try {
        let response;
        if (selectedBudgetItem?.budgetDetail == null) {
          const body = {
            categoryId: selectedBudgetItem.categoryId,
            limit: limit,
            month: formatDateMonthYear(startEndDate.startDate),
          };
          response = await createBudget(body);
        } else {
          const body = {
            categoryId: selectedBudgetItem.categoryId,
            limit: limit,
            month: formatDateMonthYear(startEndDate.startDate),
          };
          response = await updateBudget(
            { budgetId: selectedBudgetItem.budgetDetail.budgetId },
            body
          );
        }
        if (response.data.status == API_RESP_STATUS.success) {
          setLimit("");
          refetch();
          setAddNewBudgetDialog(false);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={addNewBudgetDialog}
        onClose={handleClose}
      >
        <DialogTitle>Set budget</DialogTitle>
        <DialogContent>
          <div>
            <button
              disable={true}
              // onClick={() =>
              //   dialogSelectAccount(IS_DIALOG_ACCOUNT_CATEGORY_TYPE.CATEGORY)
              // }
              type="button"
              className="btn btn-outline-primary"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                minWidth: "30vw",
                maxWidth: "30vw",
                pointerEvents: "none",
              }}
            >
              <img
                style={{ width: "35px", height: "35px" }}
                src={selectedBudgetItem.iconDetail.icon}
                alt="Small Image"
                className="img-fluid mr-2"
              />
              {selectedBudgetItem.name}
            </button>
          </div>

          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <input
              name="limit"
              type="number"
              onChange={handelAmountNotes}
              value={limit}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              required
            ></input>
            <label htmlFor="floatingTextarea">Limit</label>
          </div>
          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            {formatDateMonthYear(startEndDate.startDate)}
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-outline-primary" onClick={handleSave}>
            Save
          </button>
          <button className="btn btn-outline-primary" onClick={handleClose}>
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewBudgetDialog;
