import React, { useContext, useEffect, useMemo, useState } from "react";
import AllStateContext from "../context/allstateContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FILTER_TYPE } from "../Constant/constant";

const AddNewCategoryDialog = ({ refetch }) => {
  const {
    openMonthFilterDialog,
    setOpenMonthFilterDialog,
    selectedFilterType,
    setSelectedFilterType,
    setResetFilter,
  } = useContext(AllStateContext);

  useMemo(() => {}, []);

  const handleClose = () => {
    setOpenMonthFilterDialog(false);
  };

  const handleSave = async () => {
    setResetFilter(true);
    setOpenMonthFilterDialog(false);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        open={openMonthFilterDialog}
      >
        <DialogTitle>Date Filter</DialogTitle>
        <DialogContent>
          <>
            <div>
              <div style={{ display: "flex" }}>
                <div>
                  <div>View:</div>
                  {/* <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedFilterType(FILTER_TYPE.DAILY)}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        display:
                          selectedFilterType === FILTER_TYPE.DAILY
                            ? ""
                            : "none",
                      }}
                      src={require("../Common/Icon/icon_select.png")}
                      alt="left-arrow"
                    />
                    DAILY
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedFilterType(FILTER_TYPE.WEEK)}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        display:
                          selectedFilterType === FILTER_TYPE.WEEK ? "" : "none",
                      }}
                      src={require("../Common/Icon/icon_select.png")}
                      alt="left-arrow"
                    />
                    WEEK
                  </div> */}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedFilterType(FILTER_TYPE.MONTHLY)}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        display:
                          selectedFilterType === FILTER_TYPE.MONTHLY
                            ? ""
                            : "none",
                      }}
                      src={require("../Common/Icon/icon_select.png")}
                      alt="left-arrow"
                    />
                    MONTHLY
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedFilterType(FILTER_TYPE.MONTH_3)}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        display:
                          selectedFilterType === FILTER_TYPE.MONTH_3
                            ? ""
                            : "none",
                      }}
                      src={require("../Common/Icon/icon_select.png")}
                      alt="left-arrow"
                    />
                    3 MONTHLY
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedFilterType(FILTER_TYPE.MONTH_6)}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        display:
                          selectedFilterType === FILTER_TYPE.MONTH_6
                            ? ""
                            : "none",
                      }}
                      src={require("../Common/Icon/icon_select.png")}
                      alt="left-arrow"
                    />
                    6 MONTHLY
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedFilterType(FILTER_TYPE.YEARLY)}
                  >
                    <img
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        display:
                          selectedFilterType === FILTER_TYPE.YEARLY
                            ? ""
                            : "none",
                      }}
                      src={require("../Common/Icon/icon_select.png")}
                      alt="left-arrow"
                    />
                    YEARLY
                  </div>
                </div>
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-outline-primary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={handleSave}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewCategoryDialog;
