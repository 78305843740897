import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AllStateContext from "../../context/allstateContext";
import { FILTER_TYPE } from "../../Constant/constant";
import MothFilterDialog from "../../Common/MothFilterDialog";

const DateView = ({ totalExpense, totalIncome, dateUpdate }) => {
  const {
    setOpen,
    setRecordItem,
    startEndDate,
    setStartEndDate,
    setOpenMonthFilterDialog,
    selectedFilterType,
    resetFilter,
    setResetFilter,
    openMonthFilterDialog,
  } = useContext(AllStateContext);

  const dateSwitcher = (count) => {
    let currentDate;
    if (!startEndDate.startDate) {
      setStartEndDate({ startDate: new Date() });
      currentDate = new Date();
    } else {
      currentDate = new Date(startEndDate.startDate);
    }
    let counter = 0;
    if (count === "+") {
      if (selectedFilterType === FILTER_TYPE.MONTH_3) {
        counter = FILTER_TYPE.MONTH_3;
      } else if (selectedFilterType === FILTER_TYPE.MONTH_6) {
        counter = FILTER_TYPE.MONTH_6;
      } else if (selectedFilterType === FILTER_TYPE.YEARLY) {
        counter = FILTER_TYPE.YEARLY;
      } else {
        counter = 1;
      }
    }
    if (count === "-") {
      if (selectedFilterType === FILTER_TYPE.MONTH_3) {
        counter = -FILTER_TYPE.MONTH_3;
      } else if (selectedFilterType === FILTER_TYPE.MONTH_6) {
        counter = -FILTER_TYPE.MONTH_6;
      } else if (selectedFilterType === FILTER_TYPE.YEARLY) {
        counter = -FILTER_TYPE.YEARLY;
      } else {
        counter = -1;
      }
    }
    if (counter != 0) {
      // if (
      //   selectedFilterType === FILTER_TYPE.DAILY ||
      //   selectedFilterType === FILTER_TYPE.WEEK
      // ) {
      //   debugger;
      //   if (count === "+") {
      //     currentDate = new Date(startEndDate.endDate);
      //     const startDate = new Date(currentDate);
      //     currentDate.setDate(currentDate.getDay() + counter);
      //     const endDate = new Date(currentDate);
      //     endDate.setHours(23, 59, 59, 999);
      //     startDate.setHours(0, 0, 0, 0);
      //     setStartEndDate({ startDate, endDate });
      //   } else {
      //     currentDate = new Date(startEndDate.startDate);
      //     const endDate = new Date(currentDate);
      //     currentDate.setDate(currentDate.getDay() + counter);
      //     const startDate = new Date(currentDate);
      //     endDate.setHours(23, 59, 59, 999);
      //     startDate.setHours(0, 0, 0, 0);
      //     setStartEndDate({ startDate, endDate });
      //   }
      // } else {
      currentDate.setMonth(currentDate.getMonth() + counter);

      counter = Math.abs(counter);
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      const startDate = new Date(currentYear, currentMonth, counter);
      const endDate = new Date(currentYear, currentMonth + counter, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate.setHours(0, 0, 0, 0);
      setStartEndDate({ startDate, endDate });
      // }
    } else {
      if (selectedFilterType === FILTER_TYPE.MONTH_3) {
        counter = FILTER_TYPE.MONTH_3;
      } else if (selectedFilterType === FILTER_TYPE.MONTH_6) {
        counter = FILTER_TYPE.MONTH_6;
      } else if (selectedFilterType === FILTER_TYPE.YEARLY) {
        counter = FILTER_TYPE.YEARLY;
      }
      currentDate.setMonth(currentDate.getMonth());

      counter = Math.abs(counter);
      if (counter === 0) {
        counter = 1;
      }
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      const startDate = new Date(currentYear, currentMonth);
      const endDate = new Date(currentYear, currentMonth + counter, 0);
      endDate.setHours(23, 59, 59, 999);
      startDate.setHours(0, 0, 0, 0);
      setStartEndDate({ startDate, endDate });
    }
  };
  useMemo(() => {
    if (resetFilter) {
      setResetFilter(false);
      dateSwitcher("");
    }
  }, [resetFilter]);

  return (
    <>
      <div
        style={{
          display: "flex",
          paddingBottom: "10px",
          paddingTop: "10px",
          justifyContent: "space-between",
          position: "relative",
          marginTop: "60px",
        }}
        className="container"
      >
        {openMonthFilterDialog && <MothFilterDialog />}

        <div>
          <img
            onClick={() => dateSwitcher("-")}
            style={{ height: "30px", width: "30px", cursor: "pointer" }}
            src={require("../../Common/Icon/left-arrow.png")}
            alt="left-arrow"
          />
        </div>
        <div style={{ fontSize: "20px", fontWeight: "700" }}>{`${moment(
          startEndDate.startDate
        ).format("DD MMM, YYYY")} - ${moment(startEndDate.endDate).format(
          "DD MMM, YYYY"
        )}`}</div>
        <div>
          <img
            onClick={() => dateSwitcher("+")}
            style={{ height: "30px", width: "30px", cursor: "pointer" }}
            src={require("../../Common/Icon/right-arrow.png")}
            alt="left-arrow"
          />
          <img
            style={{
              height: "40px",
              width: "40px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() => setOpenMonthFilterDialog(true)}
            src={require("../../Common/Icon/menu-bar.png")}
            alt="menu-bar"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          paddingBottom: "10px",
          paddingTop: "10px",
          justifyContent: "space-between",
        }}
        className="container"
      >
        <div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>EXPENSE</div>
          <div
            style={{
              fontSize: "13px",
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
              color: "red",
            }}
          >
            {`₹${totalExpense ? totalExpense : 0}`}
          </div>
        </div>
        <div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>INCOME</div>
          <div
            style={{
              fontSize: "13px",
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
              color: "green",
            }}
          >
            {`₹${totalIncome ? totalIncome : 0}`}
          </div>
        </div>
        <div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>BALANCE</div>
          <div
            style={{
              fontSize: "13px",
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {`₹${(totalIncome - totalExpense
              ? totalIncome - totalExpense
              : 0
            ).toFixed(2)}`}
          </div>
        </div>
      </div>
    </>
  );
};

export default DateView;
