import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DateView from "../Record/DateView";
import { useNavigate } from "react-router-dom";
import { API_RESP_STATUS } from "../../Constant/constant";
import { getBudgetAnalysis } from "../Apis/apis";
import ProgressBar from "@ramonak/react-progress-bar";
import AddDailog from "../Record/AddDailog";
import AllStateContext from "../../context/allstateContext";
import AccountDialog from "../Record/AccountDialog";
import DateDialog from "../../Common/DateDialog";
import TimeDialog from "../../Common/TimeDialog";
import { useQuery } from "react-query";
import { formatDate } from "../../Common/Common";

const Analisys = () => {
  const history = useNavigate();
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [options, setOptions] = useState({});
  const { openAccount, openDateDialog, openTimeDialog, startEndDate } =
    useContext(AllStateContext);
  const [selectedOption, setSelectedOption] = useState("expense");

  const handleSelectionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const { data, error, isLoading, refetch } = useQuery(
    ["recordListApiCall", startEndDate, selectedOption],
    async () => {
      const showAll = true;
      const response = await getBudgetAnalysis(
        { type: selectedOption === "expense" ? 1 : 2 },
        formatDate(startEndDate.startDate),
        "",
        showAll
      );
      if (response.data.status == API_RESP_STATUS.success) {
        let dataArray = [];
        let nameArray = [];
        await response.data.payload.data.map((item) => {
          if (item?.spent) {
            dataArray.push(item.spent);
            nameArray.push(item.name);
          }
        });
        setSeries(dataArray);
        setLabels(nameArray);

        await setOptions({
          chart: {
            width: 380,
            type: "pie",
          },
          labels: nameArray,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        });
      }
      return response.data;
    }
  );
  if (error) {
    if (error.response.status == API_RESP_STATUS.unauthorized) {
      localStorage.removeItem("token");
      history("/login");
    }
  }

  return (
    <div className="container">
      <div className="container">
        {isLoading ? (
          <div className="container"></div>
        ) : (
          <>
            <DateView
              totalExpense={data?.payload?.totalInEx?.totalExpense}
              totalIncome={data?.payload?.totalInEx?.totalIncome}
              dateUpdate={"handleStartEndDate" && ""}
            />
            <AddDailog fetchData={refetch} />
            {openAccount && <AccountDialog />}
            {openDateDialog && <DateDialog />}
            {openTimeDialog && <TimeDialog />}
            <div className="container">
              <select
                value={selectedOption}
                onChange={handleSelectionChange}
                className={`form-select ${
                  selectedOption === "income"
                    ? "bg-success text-white"
                    : selectedOption === "expense"
                    ? "bg-danger text-white"
                    : ""
                }`}
              >
                <option value="income">Income</option>
                <option value="expense">Expense</option>
              </select>
            </div>
            <div
              id="chart"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ReactApexChart
                options={options}
                series={series}
                type="pie"
                width={400}
              />
            </div>
            <div>
              <ul className="list-group" style={{ marginTop: "20px" }}>
                {data && (
                  <Fragment key={data}>
                    {data?.payload?.data?.map(
                      (item) =>
                        item.spent && (
                          <li
                            className="list-group-item"
                            key={item?.categoryId}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ display: "flex", gap: "12px" }}>
                                <div>
                                  <img
                                    style={{ height: "50px", width: "50px" }}
                                    src={item?.iconDetail?.icon}
                                    alt={item?.name}
                                  />
                                </div>
                                <div>
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>{item.name}</div>
                                      <div style={{ fontWeight: "bold" }}>
                                        {(
                                          (item.spent /
                                            data?.payload?.totalInEx
                                              ?.totalSpent) *
                                          100
                                        ).toFixed(2) + "%"}
                                      </div>
                                    </div>
                                    <div>
                                      <ProgressBar
                                        completed={(
                                          (item.spent /
                                            data?.payload?.totalInEx
                                              ?.totalSpent) *
                                          100
                                        ).toFixed(1)}
                                        maxCompleted={100}
                                        width="56vw"
                                        bgColor="#008ffb"
                                        customLabel={" "}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    color: item?.type === 1 ? "red" : "green",
                                  }}
                                >
                                  {item?.type === 1
                                    ? `-₹${item.spent}`
                                    : `₹${item.spent}`}
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                    )}
                  </Fragment>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Analisys;
