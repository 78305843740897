import React, { useContext, useEffect, useMemo, useState } from "react";
import AllStateContext from "../context/allstateContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { API_RESP_STATUS } from "../Constant/constant";
import {
  createContactTransaction,
  updateAccount,
} from "../components/Apis/apis";

const AddNewContactDialog = ({ refetch }) => {
  const [addContact, setAddContact] = useState({
    name: "",
    image: "",
    phone: "",
    email: "",
    address: "",
  });
  const {
    selectedAccountItem,
    setSelectedAccountItem,
    setAddContactDialog,
    addContactDialog,
  } = useContext(AllStateContext);

  useMemo(() => {
    setAddContact({
      balance: selectedAccountItem.balance,
      name: selectedAccountItem.name,
    });
  }, [addContactDialog]);

  const handleClose = () => {
    setAddContactDialog(false);
    setSelectedAccountItem("");
    setAddContact({ balance: "", name: "" });
  };
  const handelAmountNotes = (e) => {
    const { name, value } = e.target;
    setAddContact((data) => {
      return { ...data, [name]: value };
    });
  };
  const handleSave = async () => {
    if (!addContact.name) {
      alert("Name is required ...!");
    } else if (!addContact.phone) {
      alert("Phone is required ...!");
    } else {
      try {
        let response;
        if (!selectedAccountItem) {
          const body = {
            ...addContact,
          };
          response = await createContactTransaction(body);
        } else {
          const body = {
            name: addContact.name,
            balance: addContact.balance,
          };
          response = await updateAccount(
            { accountId: selectedAccountItem.accountId },
            body
          );
        }
        if (
          response?.data &&
          response?.data?.status == API_RESP_STATUS.success
        ) {
          refetch();
          setAddContactDialog(false);
          alert(response.data.message);
          setSelectedAccountItem("");
          setAddContact({ balance: "", name: "" });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        alert(error.response.data.message);
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        open={addContactDialog}
      >
        <DialogTitle>Create Contact</DialogTitle>
        <DialogContent>
          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <input
              name="name"
              type="string"
              onChange={handelAmountNotes}
              value={addContact.name}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              required
            ></input>
            <label htmlFor="floatingTextarea">Name</label>
          </div>
          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <input
              name="phone"
              type="number"
              onChange={handelAmountNotes}
              value={addContact.phone}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              required
            ></input>
            <label htmlFor="floatingTextarea">Phone Number</label>
          </div>
          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <input
              name="email"
              type="string"
              onChange={handelAmountNotes}
              value={addContact.email}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              required
            ></input>
            <label htmlFor="floatingTextarea">Email</label>
          </div>
          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <input
              name="address"
              type="string"
              onChange={handelAmountNotes}
              value={addContact.address}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              required
            ></input>
            <label htmlFor="floatingTextarea">Address</label>
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-outline-primary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={handleSave}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewContactDialog;
