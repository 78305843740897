import React, { useContext, useEffect, useMemo, useState } from "react";
import AllStateContext from "../context/allstateContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { API_RESP_STATUS } from "../Constant/constant";
import {
  createAccount,
  getIconList,
  updateAccount,
  updateBudget,
} from "../components/Apis/apis";
import { useQuery } from "react-query";

const AddNewAccountDialog = ({ refetch }) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [balanceName, setBalanceName] = useState({ balance: "", name: "" });
  const {
    selectedAccountItem,
    setSelectedAccountItem,
    addNewAccountDialog,
    setAddNewAccountDialog,
  } = useContext(AllStateContext);

  useMemo(() => {
    setBalanceName({
      balance: selectedAccountItem.balance,
      name: selectedAccountItem.name,
    });
    setSelectedItem(selectedAccountItem.iconId);
  }, [addNewAccountDialog]);

  const {
    data: iconData,
    error: iconError,
    isLoading: iconIsLoadding,
    refetch: iconRefetch,
  } = useQuery(["iconListApiCall"], async () => {
    const showAll = true;
    const response = await getIconList(showAll, { type: 1 });
    return response.data.payload.data;
  });

  const handleClose = () => {
    setAddNewAccountDialog(false);
    setSelectedAccountItem("");
    setBalanceName({ balance: "", name: "" });
  };
  const handelAmountNotes = (e) => {
    const { name, value } = e.target;
    setBalanceName((data) => {
      return { ...data, [name]: value };
    });
  };
  const handleSave = async () => {
    if (!balanceName.balance && !balanceName.balance == 0) {
      alert("Balance is required ...!");
    } else if (!balanceName.name) {
      alert("Name is required ...!");
    } else if (!selectedItem && !selectedItem == 0) {
      alert("Please select icon...!");
    } else {
      try {
        let response;
        if (!selectedAccountItem) {
          const body = {
            name: balanceName.name,
            balance: balanceName.balance,
            iconId: selectedItem,
          };
          response = await createAccount(body);
        } else {
          const body = {
            name: balanceName.name,
            balance: balanceName.balance,
            iconId: selectedItem,
          };
          response = await updateAccount(
            { accountId: selectedAccountItem.accountId },
            body
          );
        }
        if (
          response?.data &&
          response?.data?.status == API_RESP_STATUS.success
        ) {
          refetch();
          setAddNewAccountDialog(false);
          alert(response.data.message);
          setSelectedAccountItem("");
          setBalanceName({ balance: "", name: "" });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        alert(error.response.data.message);
      }
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        open={addNewAccountDialog}
      >
        <DialogTitle>Edit Account</DialogTitle>
        <DialogContent>
          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <input
              name="balance"
              type="number"
              onChange={handelAmountNotes}
              value={balanceName.balance}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
            ></input>
            <label htmlFor="floatingTextarea">Initial amount</label>
          </div>
          <div
            className="form-floating"
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <input
              name="name"
              type="string"
              onChange={handelAmountNotes}
              value={balanceName.name}
              className="form-control"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              required
            ></input>
            <label htmlFor="floatingTextarea">Name</label>
          </div>
          Icon
          <div
            className="form-floating"
            style={{
              marginBottom: "10px",
              border: "1px solid gray",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <div className="horizontal-list-view">
              {iconData?.map((iconItem) => (
                <div
                  className={`list-item ${
                    selectedItem === iconItem.iconId ? "selected" : ""
                  }`}
                  key={iconItem.iconId}
                  onClick={() => setSelectedItem(iconItem.iconId)}
                >
                  <img
                    style={{ width: "35px", height: "35px" }}
                    src={iconItem.icon}
                    alt="Small Image"
                    className="img-fluid mr-2"
                  />
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-outline-primary" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-outline-primary" onClick={handleSave}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewAccountDialog;
