import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_RESP_STATUS } from "../../Constant/constant";
import { getContactList, getContactTransactionList } from "../Apis/apis";
import AddDailog from "../Record/AddDailog";
import AllStateContext from "../../context/allstateContext";
import AccountDialog from "../Record/AccountDialog";
import DateDialog from "../../Common/DateDialog";
import TimeDialog from "../../Common/TimeDialog";
import { useQuery } from "react-query";
import DeletedConfirmationDialog from "../../Common/DeletedConfirmationDialog";
import AddNewContactDialog from "../../Common/AddNewContactDialog";
import AddNewContactTransactionDialog from "../../Common/AddNewContactTransactionDialog";

//dialog

const ContactTransaction = () => {
  const history = useNavigate();
  const {
    openAccount,
    openDateDialog,
    openTimeDialog,
    openDeleteConfirmationDialog,
    addContactDialog,
    setAddContactDialog,
    setAddContactTransactionDialog,
  } = useContext(AllStateContext);
  const [dataT, setDataT] = useState("");

  const { data, error, isLoading, refetch } = useQuery(
    ["contactListApiCall"],
    async () => {
      const response = await getContactList();
      return response.data;
    }
  );
  if (error) {
    if (error.response.status === API_RESP_STATUS.unauthorized) {
      localStorage.removeItem("token");
      history("/login");
    }
  }

  const contactTransaction = async (contactId) => {
    let filter = { contactId: contactId };
    const setDataTransaction = await getContactTransactionList(filter);
    debugger;
    setDataT(setDataTransaction.data);
  };
  const handelDeleted = async () => {
    alert("Important: Functionality Under Development - Proceed with Caution");
  };

  return (
    <div className="container">
      <div className="container">
        {isLoading ? (
          <div className="container">
            <h1>Data Loading...!</h1>
          </div>
        ) : (
          <>
            <>
              {/* <div
                style={{
                  display: "flex",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                  justifyContent: "space-between",
                }}
                className="container"
              >
                <div>
                  <div style={{ fontSize: "15px", fontWeight: "700" }}>
                    EXPENSE SO FAR
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "center",
                      color: "red",
                    }}
                  >
                    {`₹${
                      data?.payload?.totalInEx ? data?.payload?.totalInEx : 0
                    }`}
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: "15px", fontWeight: "700" }}>
                    INCOME SO FAR
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "center",
                      color: "green",
                    }}
                  >
                    {`₹${
                      data?.payload?.totalInBal ? data?.payload?.totalInBal : 0
                    }`}
                  </div>
                </div>
                <div>
                  <div style={{ fontSize: "15px", fontWeight: "700" }}>
                    TOTAL BALANCE
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {`₹${(data?.payload?.totalInBal - data?.payload?.totalInEx
                      ? data?.payload?.totalInBal - data?.payload?.totalInEx
                      : 0
                    ).toFixed(2)}`}
                  </div>
                </div>
              </div> */}
            </>
            <AddDailog fetchData={refetch} />
            {openAccount && <AccountDialog />}
            {openDateDialog && <DateDialog />}
            {openTimeDialog && <TimeDialog />}
            {addContactDialog && <AddNewContactDialog refetch={refetch} />}
            {setAddContactTransactionDialog && (
              <AddNewContactTransactionDialog refetch={refetch} />
            )}
            {openDeleteConfirmationDialog && (
              <DeletedConfirmationDialog isDeleted={handelDeleted} />
            )}
            <div
              id="chart"
              style={{ display: "flex", justifyContent: "center" }}
            ></div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ul
                className="list-group"
                style={{ marginTop: "20px", width: "40%" }}
              >
                {data && (
                  <Fragment key={data}>
                    {data?.payload?.data
                      ?.filter((item) => item.contactId) // Filter out items with null budgetDetail
                      .map((item) => (
                        <li className="list-group-item" key={item?.contactId}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={() => contactTransaction(item.contactId)}
                          >
                            <div style={{ display: "flex", gap: "12px" }}>
                              <div>
                                <img
                                  style={{ height: "50px", width: "50px" }}
                                  src={
                                    item?.iconDetail?.icon ||
                                    require("../../Common/Icon/icon_select.png")
                                  }
                                  alt={item?.name}
                                />
                              </div>
                              <div
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  {item.name}
                                </div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    color: item?.balance < 0 ? "red" : "green",
                                  }}
                                >
                                  {item?.balance < 0
                                    ? `-₹${Math.abs(item.balance || 0)}`
                                    : `₹${item.balance || 0}`}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                gap: "10px",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  {item.name}
                                </div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    color: item?.balance < 0 ? "red" : "green",
                                  }}
                                >
                                  {item?.balance < 0
                                    ? `-₹${Math.abs(item.balance || 0)}`
                                    : `₹${item.balance || 0}`}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </Fragment>
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      marginBottom: "15px",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    onClick={() => setAddContactDialog(true)}
                  >
                    <img
                      style={{ width: "20px" }}
                      id="basic-button"
                      aria-haspopup="true"
                      src={require("../../Common/Icon/plus.png")}
                      alt="more_image"
                    />
                    ADD NEW CONTACT
                  </button>
                </div>
              </ul>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px",
                }}
              ></div>
              <ul
                className="list-group"
                style={{ marginTop: "20px", width: "40%" }}
              >
                {dataT && (
                  <Fragment key={dataT}>
                    {dataT?.payload?.data
                      ?.filter((item) => item?.contactsTransactionId) // Filter out items with null budgetDetail
                      .map((item) => (
                        <li
                          className="list-group-item"
                          key={item?.contactsTransactionId}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex", gap: "12px" }}>
                              <div
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ fontWeight: "bold" }}>
                                  {item.amount}
                                </div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "700",
                                    color: item?.amount < 0 ? "red" : "green",
                                  }}
                                >
                                  {item?.amount < 0
                                    ? `₹${Math.abs(item.amount)}`
                                    : `₹${item.amount}`}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </Fragment>
                )}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      marginBottom: "15px",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    onClick={() => setAddContactTransactionDialog(true)}
                  >
                    Received
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      marginBottom: "15px",
                      alignItems: "center",
                      gap: "5px",
                    }}
                    onClick={() => setAddContactTransactionDialog(true)}
                  >
                    Given
                  </button>
                </div>
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactTransaction;
