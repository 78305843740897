import React, { useEffect, useState } from "react";
import { login } from "./Apis/api";
import { useNavigate } from "react-router-dom";
import { setAxiosConfig } from "../Apis/apis";
export const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const history = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") != null
    ) {
      // Redirect to the home page
      history("/login");
      localStorage.removeItem("token");
      setAxiosConfig();
    }
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      //   const response = await axios.post("/login", { phoneNumber, password });
      const response = await login(phoneNumber, password);
      localStorage.setItem("token", response.data.payload.token);
      setAxiosConfig();

      // Redirect to the home page
      history("/home");

      // Do something with the response, such as storing tokens or redirecting to a new page
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred during sign in.");
      }
    }
  };
  return (
    <div className="container">
      {error && <div className="alert alert-danger">{error}</div>}
      <h1>Sign In</h1>
      <form onSubmit={handleSignIn}>
        <div className="mb-3">
          <label htmlFor="phoneNumber" className="form-label">
            Phone Number
          </label>
          <input
            type="number"
            className="form-control"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Sign In
        </button>
      </form>
    </div>
  );
};
