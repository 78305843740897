import React, { Fragment, useContext, useMemo, useState } from "react";
import { getTransactionList } from "../Apis/apis";
import moment from "moment/moment";
import DateView from "./DateView";
import AddDailog from "./AddDailog";
import AccountDialog from "./AccountDialog";
import AllStateContext from "../../context/allstateContext";
import DateDialog from "../../Common/DateDialog";
import TimeDialog from "../../Common/TimeDialog";
import MothFilterDialog from "../../Common/MothFilterDialog";
import { API_RESP_STATUS } from "../../Constant/constant";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export const Record = () => {
  const history = useNavigate();

  // const [data, setData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  // const [startEndDate, setStartEndDate] = useState({});
  const {
    openTimeDialog,
    setOpen,
    openDateDialog,
    openAccount,
    setRecordItem,
    startEndDate,
    setStartEndDate,
    searchDetail,
    openMonthFilterDialog,
  } = useContext(AllStateContext);
  const [selectedOption, setSelectedOption] = useState("both");

  const handleSelectionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const { data, error, isLoading, refetch } = useQuery(
    ["recordListApiCall", startEndDate, searchDetail, selectedOption],
    async () => {
      const showAll = true;
      let search = { notes: searchDetail.name };
      if (selectedOption === "expense") {
        search["type"] = 1;
      } else if (selectedOption === "income") {
        search["type"] = 2;
      }
      const response = await getTransactionList(
        search,
        startEndDate.startDate,
        startEndDate.endDate,
        showAll
      );
      setGroupData(
        response.data?.payload?.data?.reduce((acc, item) => {
          const date = new Date(item.date).toLocaleDateString("en-IN");
          acc[date] = acc[date] || {
            items: [],
            sumDebit: 0,
            sumCredit: 0,
          };
          acc[date].items.push(item);

          if (item.type === 1) {
            acc[date].sumDebit += item.amount;
          } else if (item.type === 2) {
            acc[date].sumCredit += item.amount;
          }

          return acc;
        }, {})
      );
      return response.data;
    }
  );
  if (error) {
    if (error.response.status == API_RESP_STATUS.unauthorized) {
      localStorage.removeItem("token");
      history("/login");
    }
  }

  const itemClick = (item) => {
    setOpen(true);
    setRecordItem(item);
  };

  function ListItem({ item }) {
    const handleDelete = (amount) => {
      alert(amount);
    };

    return (
      <div className={`list-group-item `}>
        <div
          style={{
            display: "flex",
            gap: "30px",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
            <div>
              <img
                style={{ height: "50px", width: "50px" }}
                src={item?.categoryDetails?.iconDetail?.icon}
                alt={item?.categoryDetails?.name}
              />
            </div>
            <div>
              <div style={{ fontSize: "15px", fontWeight: "700" }}>
                {item?.categoryDetails?.name}
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <div>
                  <img
                    style={{ height: "25px", width: "25px" }}
                    src={item?.accountDetails?.iconDetail?.icon}
                    alt={item?.accountDetails?.iconDetail?.name}
                  />
                </div>
                <div>{item?.accountDetails?.name}</div>
              </div>
              <div style={{ fontSize: "13px", fontWeight: "400" }}>
                {item?.notes}
              </div>
            </div>
          </div>

          <div>
            <div
              style={{ display: "flex", gap: "10px" }}
              className="delete-icon"
            >
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "700",
                  color: item?.type === 1 ? "red" : "green",
                }}
              >
                {item?.type === 1 ? `-₹${item.amount}` : `₹${item.amount}`}
              </div>
              <img
                style={{ width: "20px", height: "20px" }}
                onClick={() => handleDelete(item.amount)}
                src={require("../../Common/Icon/delete_icon.png")}
                alt="icon-select"
                className="img-fluid mr-2"
              />
              <img
                style={{ width: "20px", height: "20px" }}
                onClick={() => itemClick(item)}
                src={require("../../Common/Icon/edit.png")}
                alt="icon-select"
                className="img-fluid mr-2"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="container">
        {isLoading ? (
          <div className="container">
            <h1>Data Loading...!</h1>
          </div>
        ) : (
          <>
            <div
              className="fixed-top"
              style={{ background: "white", zIndex: 1 }}
            >
              <DateView
                totalExpense={data?.payload?.totalInEx?.totalExpense}
                totalIncome={data?.payload?.totalInEx?.totalIncome}
              />
              <AddDailog fetchData={refetch} />
              <div className="container">
                <select
                  value={selectedOption}
                  onChange={handleSelectionChange}
                  className={`form-select ${
                    selectedOption === "income"
                      ? "bg-success text-white"
                      : selectedOption === "expense"
                      ? "bg-danger text-white"
                      : selectedOption === "both"
                      ? "bg-white text-black"
                      : ""
                  }`}
                >
                  <option value="both">Both</option>
                  <option value="income">Income</option>
                  <option value="expense">Expense</option>
                </select>
              </div>
            </div>
            {openAccount && <AccountDialog />}
            {openDateDialog && <DateDialog />}
            {openTimeDialog && <TimeDialog />}
            <ul className="list-group" style={{ marginTop: "230px" }}>
              {groupData &&
                Object.entries(groupData)?.map(
                  ([date, { items, sumDebit, sumCredit }]) => (
                    <Fragment key={date}>
                      <li className="list-group-item">
                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: "15px",
                                fontWeight: "700",
                              }}
                            >
                              {moment(date, "D/M/YYYY").format("MMM D, dddd")}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  color: "green",
                                  fontSize: "15px",
                                  fontWeight: "700",
                                }}
                              >
                                INCOME : {sumCredit}
                              </div>
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "15px",
                                  fontWeight: "700",
                                }}
                              >
                                EXPENSE : {sumDebit}
                              </div>

                              <div
                                style={{
                                  color: "black",
                                  fontSize: "15px",
                                  fontWeight: "700",
                                }}
                              >
                                BALANCE : {sumCredit - sumDebit}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {items?.map((item) => (
                        <ListItem
                          key={item?.transactionId}
                          item={item}
                          // itemClick={itemClick}
                        />
                      ))}
                    </Fragment>
                  )
                )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
