import React, { Fragment } from "react";
import { Navbar } from "../components/NavBar/Navbar";
import { Outlet } from "react-router-dom";

const MainPage = () => {
  return (
    <Fragment>
      <Navbar
        title="my-money"
        home="Record"
        analysis="Analysis"
        budgets="Budgets"
        account="Accounts"
        categories="Categories"
        contact="Contact"
      />
      <Outlet />
    </Fragment>
  );
};

export default MainPage;
