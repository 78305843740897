import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setAxiosConfig } from "../Apis/apis";
import AllStateContext from "../../context/allstateContext";

export const Navbar = ({
  title,
  home,
  analysis,
  budgets,
  account,
  categories,
  contact,
}) => {
  const { setOpen, setRecordItem, searchDetail, setSearchDetail } =
    useContext(AllStateContext);
  const [selected, setSelected] = useState("Home");
  const [hideMobileMenu, setHideMobileMenu] = useState(false);
  const handelAmountNotes = (e) => {
    const { name, value } = e.target;
    setSearchDetail((data) => {
      return { ...data, [name]: value };
    });
  };
  const changeTitle = (title_name) => {
    setSelected(title_name);
    setHideMobileMenu(false);
    document.title = title_name;
  };
  const history = useNavigate();
  const logoutHandel = () => {
    localStorage.removeItem("token");
    setAxiosConfig();
    history("/Login");
  };
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div className="container-fluid">
        <Link
          className="navbar-brand"
          to="/"
          onClick={() => changeTitle("Home")}
        >
          {title}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setHideMobileMenu(true)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${hideMobileMenu ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={selected === "Home" ? "nav-link active" : "nav-link"}
                aria-current="page"
                to="/home"
                onClick={() => changeTitle("Home")}
              >
                {home}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  selected === "Analysis" ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                to="/analysis"
                onClick={() => changeTitle("Analysis")}
              >
                {analysis}
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                className={
                  selected === "Budgets" ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                to="/budgets"
                onClick={() => changeTitle("Budgets")}
              >
                {budgets}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  selected === "Accounts" ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                to="/account"
                onClick={() => changeTitle("Accounts")}
              >
                {account}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  selected === "Categories" ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                to="/categories"
                onClick={() => changeTitle("Categories")}
              >
                {categories}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  selected === "Contact" ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                to="/contact"
                onClick={() => changeTitle("Contact")}
              >
                {contact}
              </Link>
            </li>
          </ul>

          <div className="form-inline my-2 my-lg-0 d-flex gap-2">
            <input
              name="name"
              className="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={handelAmountNotes}
              value={searchDetail.name}
            />
          </div>

          <img
            style={{
              height: "40px",
              width: "40px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
            src={require("../../Common/Icon/plus.png")}
            alt="menu-bar"
            type="button"
            onClick={() => {
              setOpen(true);
              setRecordItem("");
            }}
          />

          <button
            className="btn btn-outline-success"
            type="submit"
            onClick={logoutHandel}
          >
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};
