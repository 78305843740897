import React, { useState } from "react";
import AllStateContext from "./allstateContext";
import {
  IS_DIALOG_ACCOUNT_CATEGORY_TYPE,
  TRANSACTION_TYPE,
} from "../Constant/constant";
import { currentStartEndDate } from "../Common/Common";

const AllStates = (props) => {
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState("");
  const [openAccount, setOpenAccount] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isAccountOrCategoryDialog, setIsAccountOrCategoryDialog] = useState(
    IS_DIALOG_ACCOUNT_CATEGORY_TYPE.ACCOUNT
  );
  const [transactionType, setTransactionType] = useState(
    TRANSACTION_TYPE.EXPENSE
  );
  const [openDateDialog, setOpenDateDialog] = useState("");
  const [openTimeDialog, setOpenTimeDialog] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [recordItem, setRecordItem] = useState("");
  const [startEndDate, setStartEndDate] = useState(currentStartEndDate());
  const [addNewBudgetDialog, setAddNewBudgetDialog] = useState(false);
  const [selectedBudgetItem, setSelectedBudgetItem] = useState("");
  const [addNewAccountDialog, setAddNewAccountDialog] = useState(false);
  const [selectedAccountItem, setSelectedAccountItem] = useState("");
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState("");
  const [openAddNewCategoryDialog, setOpenAddNewCategoryDialog] = useState("");
  const [selectedCategoriesItem, setSelectedCategoriesItem] = useState("");
  const [searchDetail, setSearchDetail] = useState({ balance: "", name: "" });
  const [openMonthFilterDialog, setOpenMonthFilterDialog] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState(2);
  const [resetFilter, setResetFilter] = useState(false);
  const [addContactDialog, setAddContactDialog] = useState(false);
  const [addContactTransactionDialog, setAddContactTransactionDialog] =
    useState(false);
  return (
    <AllStateContext.Provider
      value={{
        open,
        setOpen,
        token,
        setToken,
        openAccount,
        setOpenAccount,
        selectedAccount,
        setSelectedAccount,
        selectedCategory,
        setSelectedCategory,
        isAccountOrCategoryDialog,
        setIsAccountOrCategoryDialog,
        transactionType,
        setTransactionType,
        openDateDialog,
        setOpenDateDialog,
        selectedDate,
        setSelectedDate,
        openTimeDialog,
        setOpenTimeDialog,
        selectedTime,
        setSelectedTime,
        recordItem,
        setRecordItem,
        startEndDate,
        setStartEndDate,
        addNewBudgetDialog,
        setAddNewBudgetDialog,
        selectedBudgetItem,
        setSelectedBudgetItem,
        addNewAccountDialog,
        setAddNewAccountDialog,
        selectedAccountItem,
        setSelectedAccountItem,
        openDeleteConfirmationDialog,
        setOpenDeleteConfirmationDialog,
        openAddNewCategoryDialog,
        setOpenAddNewCategoryDialog,
        selectedCategoriesItem,
        setSelectedCategoriesItem,
        searchDetail,
        setSearchDetail,
        openMonthFilterDialog,
        setOpenMonthFilterDialog,
        selectedFilterType,
        setSelectedFilterType,
        resetFilter,
        setResetFilter,
        addContactDialog,
        setAddContactDialog,
        addContactTransactionDialog,
        setAddContactTransactionDialog,
      }}
    >
      {props.children}
    </AllStateContext.Provider>
  );
};

export default AllStates;
